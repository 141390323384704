export default function HeadsetIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      {...props}
    >
      <path
        d="M11.7812 6.49983V10.5623C11.7812 11.1011 11.5672 11.6177 11.1863 11.9986C10.8054 12.3796 10.2887 12.5936 9.75 12.5936H6.90625C6.79851 12.5936 6.69517 12.5508 6.61899 12.4746C6.5428 12.3984 6.5 12.2951 6.5 12.1873C6.5 12.0796 6.5428 11.9763 6.61899 11.9001C6.69517 11.8239 6.79851 11.7811 6.90625 11.7811H9.75C10.0732 11.7811 10.3832 11.6527 10.6118 11.4241C10.8403 11.1956 10.9688 10.8856 10.9688 10.5623H9.75C9.42677 10.5623 9.11677 10.4339 8.88821 10.2054C8.65965 9.97681 8.53125 9.66681 8.53125 9.34358V7.31233C8.53125 6.9891 8.65965 6.67911 8.88821 6.45055C9.11677 6.22199 9.42677 6.09358 9.75 6.09358H10.951C10.8741 5.24906 10.5585 4.44384 10.0411 3.77199C9.52364 3.10013 8.82572 2.58938 8.02883 2.29938C7.23194 2.00938 6.36899 1.95211 5.54077 2.13425C4.71254 2.3164 3.95325 2.73045 3.35156 3.32804C2.60338 4.06635 2.14191 5.04651 2.04953 6.09358H3.25C3.57323 6.09358 3.88323 6.22199 4.11179 6.45055C4.34035 6.67911 4.46875 6.9891 4.46875 7.31233V9.34358C4.46875 9.66681 4.34035 9.97681 4.11179 10.2054C3.88323 10.4339 3.57323 10.5623 3.25 10.5623H2.4375C2.11427 10.5623 1.80427 10.4339 1.57571 10.2054C1.34715 9.97681 1.21875 9.66681 1.21875 9.34358V6.49983C1.21989 5.45369 1.53137 4.4314 2.11377 3.56235C2.69617 2.69331 3.52331 2.01659 4.49048 1.61785C5.45765 1.21911 6.52138 1.11626 7.54703 1.32234C8.57268 1.52842 9.51413 2.03416 10.2522 2.77554C10.7395 3.26522 11.1254 3.84625 11.3878 4.48533C11.6502 5.12442 11.7839 5.80899 11.7812 6.49983Z"
        fill="#FFDE30"
      />
    </svg>
  );
}
