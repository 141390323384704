import st from "../../styles/components/IntercomCustomButton.module.scss";
import HeadsetIcon from "../../assets/icons/general/HeadsetIcon";

import { useIntercom } from "react-use-intercom";

export default function IntercomCustomButton({ isChatOpen }) {
  const { show } = useIntercom();

  const showIntercomChat = () => {
    show();
  };
  return (
    <div
      className={`${st["intercom-custom-button-container"]} ${
        isChatOpen && st["chat-open"]
      }`}
      onClick={showIntercomChat}
    >
      <HeadsetIcon />
    </div>
  );
}
